<template>
  <v-container>
    <h2 class="mb-6 justify-center">
      Sign up for our mailing list in exchange for future Information.
    </h2>
    <v-form>
      <v-text-field
        v-model="currentValue"
        label="Enter your email or another suitable offering."
        @keydown.enter.prevent
        width="80%"
        outlined
      />
      <v-btn class="mb-4" @click="submit" x-large>Confirm</v-btn>
      <Transition mode="out-in" name="fade-in">
        <div class="result-message mb-4" :key="message">{{ message }}</div>
      </Transition>
      <v-spacer class="mb-3" />
      <p class="text-subtitle-2" font-family="Terminus">
        Email sent via subscription will be for exclusively news concerning Margin of the
        Strange
      </p>
      <p class="text-subtitle-2" font-family="Terminus">
        You can unsubscribe at any time.
      </p>
    </v-form>
  </v-container>
</template>

<script>
// import { useRouter } from 'vue-router'
import { mapState, mapActions } from "vuex";

export default {
  name: "MailingList",

  data: () => ({
    message: "",
    currentValue: "",

    // All cool secret links go here!! WOW!!
    routes: [
      {
        keys: process.env.VUE_APP_SECRET_KEYS1,
        destination: process.env.VUE_APP_SECRET_DESTINATION1,
      },
      {
        keys: process.env.VUE_APP_SECRET_KEYS2,
        destination: process.env.VUE_APP_SECRET_DESTINATION2
      }
    ],
  }),

  computed: {
    ...mapState(["activePage"]),
    ...mapState(['argPath'])
  },

  methods: {
    submit() {
      // Check if value is part of the secret ARG routes. Direct if so.
      let destination = this.checkKey(this.currentValue);
      console.log(destination);
      if (destination != null) {
        // const router = useRouter()
        // router.push({ name: destination })
        this.setARGPath(destination);
      }

      // Check if value is an email address - If so, setup Sendy API request
      else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.currentValue)) {
        this.callAPI(this.currentValue);
      }

      // Show "error".
      else {
        this.message = "Nothing happened...";
      }
    },

    checkKey(_key) {
      for (var i = 0; i < this.routes.length; i++) {
        let route = this.routes[i];
        let keys = route.keys.split(", ");
        for (var j = 0; j < keys.length; j++) {
          if (_key.toLowerCase() == keys[j].toLowerCase()) {
            return route.destination;
          }
        }
      }

      return null;
    },

    async callAPI(_email) {
      try {
        const formData = new URLSearchParams();
        formData.append("api_key", process.env.VUE_APP_SENDY_API);
        formData.append("email", _email);
        formData.append("list", process.env.VUE_APP_LIST_ID);

        const response = await fetch("https://marginofthestrange.com/sendy/subscribe", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}.`);
        }

        // TODO - Can probably make this more robust at some point. Fine for now though.
        this.message = "Thank you. We will be in touch soon.";
      } catch (error) {
        console.error("An error occured:", error);
        this.message = "Something is wrong...";
      }
    },

    ...mapActions(["setActivePage"]),
    changePage(page) {
      this.setActivePage(page);
    },

    ...mapActions(['setARGPath']),
    changeARGPath(path) {
      this.setARGPath(path);
    }
  },
};
</script>

<style scoped>
/* handle fade transition */
.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 255ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}
</style>
