<template>
    <!-- Main Page -->
    <div v-if="argPath == ''">
      <!-- Actual Page Contents-->
      <v-container>
          <!-- Featured Image -->
          <v-img
            :src="require('@/assets/banner.png')"
            class="mb-6"
          >
          </v-img>
          <!-- Text Content -->
          <div class="mb-4 pa-6" font-family="Terminus">
            Margin of the Strange is a gardening-themed mystery-exploration game about the world of human dreams. You play as Ling, a young man experiencing the phenomenon of "dreaming" for seemingly the first time. He discovers that the dream world is actually a type of ecosystem -- a sprawling, crystalline, alien dimension dictated by Strange physical laws. Over the course of 6 weeks, Ling seeks to find out more about this world in order to unravel the mysteries surrounding his own life... and his own death.
          </div>
          <div class="mb-4 pa-6">
            Things you can do in this game:
            <li class="ml-6">grow plants to reverse local entropy</li>
            <li class="ml-6">make friends, they will have information</li>
            <li class="ml-6">stare at lamps and drink sugar water (moth things)</li>
            <li class="ml-6">make friends with moths, they will help you with the 3 previous things</li>
          </div>
          
          <!-- Video -->
          <center>
            <div class="video-wrap">
                <div class="video-container">
                <iframe class="mb-4" src="https://www.youtube.com/embed/Hst9j_-kkLs?si=QiZljcMkpSosA47L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
          </center>

          <!-- Social Bar -->
          <SocialBar />

          <!-- Photo Gallery -->
          <PhotoGallery class="mb-4 pa-10" width="70%" :images="galleryImages" />

          <!-- Mailing List Banner -->
          <v-img
          class="mt-6 mb-6 ml-auto mr-auto"
          :src="require('@/assets/mailing list banner.png')"
          width="70%"
          ></v-img>

          <!-- Mailing List -->
          <center>
          <MailingList class=" ml-auto mr-auto align-center justify-center" />
          </center>
          
          <!-- Spacer -->
          <v-spacer class="pb-4" height="6rem"/>

          <!-- Footer -->
          <FooterBar />
          
      </v-container>
    </div>

    <div v-if="argPath == 'Mystery'">
      <LingSecret />
    </div>

    <div v-if="argPath == 'Notes'">
      <NoteSecret />
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';

import PhotoGallery from '@/components/PhotoGallery.vue';
import MailingList from '@/components/MailingList.vue';
import SocialBar from '@/components/SocialBar.vue';

import LingSecret from '@/components/ARG/LingSecret.vue'
import NoteSecret from '@/components/ARG/NoteSecret.vue'

import FooterBar from '@/components/FooterBar.vue';

export default {
  name: 'App',

  components: {
    PhotoGallery,
    MailingList,
    SocialBar,

    LingSecret,
    NoteSecret,

    FooterBar
  },

  data: () => ({
    galleryImages: [
      {
        "id" : 0,
        "src" : require("@/assets/1 bedroom.png")
      },
      {
        "id" : 1,
        "src" : require("@/assets/2 tableroom.png")
      },
      {
        "id" : 2,
        "src" : require("@/assets/3 plants.png")
      },
      {
        "id" : 3,
        "src" : require("@/assets/4 aqueduct.png")
      },
      {
        "id" : 4,
        "src" : require("@/assets/5 hesper.png")
      },
      {
        "id" : 5,
        "src" : require("@/assets/6 crafting.png")
      },
      {
        "id" : 6,
        "src" : require("@/assets/7 rooftop.png")
      },
      {
        "id" : 7,
        "src" : require("@/assets/8 friendship.png")
      }
    ]
  }),

  computed: {
    ...mapState(['activePage']),
    ...mapState(['argPath'])
  },
  methods: {
    ...mapActions(['setActivePage']),
    changePage(page) {
      this.setActivePage(page);
    },
    ...mapActions(['setARGPath']),
    changeARGPath(path) {
      this.setARGPath(path);
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Terminus";
  src: url("@/assets/fonts/TerminusTTF.ttf");
}

div {
  font-family: "Terminus";
  font-size: x-large;
}

.video-wrap {
  max-width: 800px;
  margin: 0 auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>